export const checksums = {
  "data": "v3.3.0--leUzipIkVM",
  "content": "v3.3.0--wYRCLVM0c9"
}
export const checksumsStructure = {
  "data": "FUIB53Emfs",
  "content": "X926aAU8Oz"
}

export const tables = {
  "data": "_content_data",
  "content": "_content_content",
  "info": "_content_info"
}

export default {
  "data": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "name": "string"
    }
  },
  "content": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "sitemap": "json",
      "robots": "json",
      "head": "json",
      "schemaOrg": "json",
      "ogImage": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}